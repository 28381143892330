import request from '@/utils/request.js'

// 账号密码登录
export const login = (data) => {
  return request({
    url: '/passport/member/userLogin',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data

  })
};


// 注册
export const register = (data) => {
  return request({
    url: '/passport/member/register',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: data,
  })
};

// 退出登录
export const loginOutApi = (data) => {
  return request({
    url: '/passport/member/logout',
    method: 'POST',
    data,
  })
};
/**
 * 获取用户信息
 * /passport/member
 * 
 */
export function getMemberMsg(params) {
  return request({
    url: '/passport/member/getUserAllInfo',
    method: 'GET',
    params
  });
}
export function getMemberTotal(params) {
  return request({
    url: '/order/order/getOrderCount',
    method: 'GET',
    params
  });
}


/**
 * 第三方登录成功 回调接口
 */
export function loginCallback(uuid) {
  return request({
    url: `/passport/connect/connect/result?state=${uuid}`,
    method: 'GET'
  });
}


// 修改密码
export const changePassWord = (data) => {
  return request({
    url: '/passport/member/modifyPass',
    method: 'PUT',
    data,
  })
};

// 忘记密码
export const forgotPassword = (data) => {
  return request({
    url: '/passport/member/resetByEmail',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: data,
  })
}
