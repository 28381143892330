<template>
  <div class="headers">
    <header class="content">
      <div class="top">
        <h1 class="logo">
          <router-link to="/user">
            <img src="@/assets/image/logo.png" alt />
          </router-link>
        </h1>
        <!-- 搜索 -->
        <!-- <div class="search">
          <el-input
            placeholder="请输入内容"
            v-model="search"
            class="input-with-select"
          >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>-->
        <nav class="nav">
          <ul>
            <li
              v-for="(item, index) in routerList"
              :key="index"
              @click="switchTab(item.path)"
              :class="$route.path.includes(item.path) ? 'active' : ''"
            >
              <span>{{ item.title }}</span>
            </li>
          </ul>
        </nav>
        <div class="is-login">
          <!-- 未登录 -->
          <div class="off-line" v-if="!loginStatus">
            <router-link to="/login">{{ $lang('登录') }}</router-link>
            <i class="quarantine-icon">/</i>
            <router-link to="/register">
              <a class="register-btn">{{ $lang('注册') }}</a>
            </router-link>
          </div>
          <el-select
            class="language"
            v-model="language"
            @change="setLanguage"
            size="mini"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <!-- 已登录显示头像 -->
          <div class="on-line" v-if="loginStatus">
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                Seuarmazemdrop <i class="el-icon-arrow-down"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="Seuarmazemdrop">Seuarmazemdrop</el-dropdown-item>
                <el-dropdown-item command="3Cliques">3Cliques</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-popover
              popper-class="uc-popover"
              placement="bottom"
              width="110"
              trigger="hover"
            >
              <div class="user-btn">
                <router-link class="item go-uc" to="/user">
                  {{ $lang('个人中心') }}
                </router-link>
                <span class="item login-out" @click="setOut">
                  {{ $lang('退出登录') }}
                  <!-- 退出登录 -->
                </span>
              </div>
              <div slot="reference">
                <el-avatar size="large" :src="userInfo.face" @error="errorHandler"
                  ><i class="el-icon-user-solid"></i
                ></el-avatar>
              </div>
              <!-- <el-button>hover 激活</el-button> -->
            </el-popover>
          </div>
        </div>
      </div>
      <!-- <div class="bottom"> -->
      <!-- 定位 -->
      <!-- <div class="location">
          <i class="l-icon el-icon-location-information"></i>
          <div class="site">深圳</div>
          <i class="r-icon el-icon-arrow-down"></i>
      </div>-->

      <!-- <div class="r-links">
          <router-link class="go-cart" to="cart">
            <i class="iconfont icon-cart"></i>购物车
          </router-link>
      </div>-->
      <!-- </div> -->
    </header>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  data() {
    return {
      search: '',
      options: [
        {
          value: 'pt',
          label: 'Pt',
        },
        {
          value: 'zh',
          label: 'Zh',
        },
        {
          value: 'en',
          label: 'En'
        },
      ],
      isLogin: false,
      confirmFlag: false,

      language: 'Pt',
      colors: ['#3483fa', '#3483fa', '#3483fa'],
    };
  },
  created() {
    this.$store.commit('INIT_USER');
    this.language = localStorage.getItem('lang') || 'pt';
  },
  computed: {
    ...mapState({
      loginStatus: state => state.user.loginStatus,
      userInfo: state => state.user.userInfo,
    }),

    // 导航
    routerList() {
      return [
        // {
        //   title: '全部商品分类',
        //   path: '/home',
        // },
        {
          title: this.$lang('订单信息'),
          path: '/orderList',
        },
        {
          title: this.$lang('在线商城'),
          path: '/list',
        },
      ];
    },
  },
  methods: {
    // 退出登录
    ...mapMutations(['loginOut']),
    ...mapMutations(['INIT_I18N']),
    switchTab(url) {
      // 判断是否点击的是同一路由
      if (this.$route.path == url) return;
      if (url === '/list' && !this.userInfo.isAsaasSubscription) {
        this.$message.warning(this.$lang('你还未订阅，无法使用该功能，请尽快订阅！'))
        return
      }
      // 跳转到对应页面
      this.$router.replace(url);
    },
    errorHandler() {
      return true;
    },
    // 退出登录
    async setOut() {
      await this.$confirm(this.$lang('确定退出登录吗？'), this.$lang('提示'), {
        distinguishCancelAndClose: true,
        confirmButtonText: this.$lang('确认'),
        cancelButtonText: this.$lang('取消'),
        type: 'warning',
      })
        .then(res => {
          if (res == 'confirm') {
            this.loginOut();
          }
        })
        .catch(action => {
          console.log(action);
        });
    },
    setLanguage(v) {
      // let locale = localStorage.getItem('lang') || 'pt';
      this.language = v;
      // 切换语言
      this.$i18n.locale = this.language;
      localStorage.setItem('lang', this.language);

      // 传递i18n给JS
      const langList = {
        // loginOut: this.$t('storeUser.loginOut'), //确定退出登录吗？
        // changePwdTips: this.$t('storeUser.changePwdTips'), //密码修改成功，请重新登录！
        // btnConfirm: this.$t('common.btnConfirm'), //确认
        // btnCancel: this.$t('common.btnCancel'), //取消
        // titleTip: this.$t('common.titleTip'), //提示
        outTips: this.$lang('退出成功'), //退出成功
        tipsLogin: this.$lang('请登录后执行此操作！'), //请登录后执行此操作！
        tipsTitle: this.$lang('请登录'), //请登录
        nowLogin: this.$lang('立即登录'), //立即登录
        browse: this.$lang('继续浏览'), //继续浏览
        errCode1: this.$lang('连接超时，请稍候再试！'), //连接超时，请稍候再试！
        errCode2: this.$lang('网络错误，请稍后再试！'), //网络错误，请稍后再试！
      };

      this.INIT_I18N(langList);

      // this.$router.go(0);
    },
    handleCommand(cmd) {
      if (cmd === '3Cliques') {
        window.open(`https://www.3cliques.io/authentication?alias=${this.userInfo.username}&code=${this.userInfo.code}`, '_blank')
      }
    }
  },
};
</script>

<style lang="less" scoped>
.user-btn {
  display: flex;
  flex-direction: column;
  .item {
    line-height: 30px;
    cursor: pointer;
    text-align: center;
    padding: 5px 0;
    border-radius: 4px;
    &:hover {
      background: rgba(52, 131, 250, 0.2);
      color: #3483fa;
    }
  }
}

.headers {
  position: relative;
  z-index: 10;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  .content {
    width: 1200px;
    margin: auto;
  }
  // top bar
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 40px;
    margin-right: 20px;
  }
  .top {
    display: flex;
    padding: 10px 0;
    justify-content: space-between;
    align-items: center;
  }
  .search {
    flex: 1;
    ::v-deep .input-with-select {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.767);
      border-radius: 5px;
      .el-input__inner {
        border: none;
        transition: 0.2s;
        font-size: 14px;
        color: #000;
        border: 2px solid transparent;
      }
      .el-input__inner:focus {
        border: 2px solid #000;
        border-right: 0;
      }
      .el-input-group__append {
        border: none;
        .el-button {
          background: #000;
          border-radius: 0 4px 4px 0;
          width: 80px;
          display: flex;
          justify-content: center;
        }
        .el-icon-search {
          color: #fff;
          font-size: 16px;
        }
      }
    }
  }
  .is-login {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 330px;
    margin-left: 20px;
    .off-line {
      font-size: 16px;
      color: #000;
      display: flex;
      align-items: center;
      padding-right: 20px;
      * {
        color: #000;
      }
      .quarantine-icon {
        font-size: 20px;
        font-family: 'Arial';
        margin: 0 8px -4px;
      }
    }
    .on-line {
      display: flex;
      align-items: center;
      margin-left: 20px;
      padding-right: 20px;
    }
  }

  // 底部bar
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 20px;
    .location {
      display: flex;
      align-items: center;
      cursor: pointer;
      border: 1px transparent solid;
      color: #fff;
      height: 26px;
      &:hover .l-icon {
        animation: upDown 1s;
      }
      .l-icon {
        font-size: 20px;
        margin-right: 6px;
      }

      .site {
        font-size: 16px;
      }
      .r-icon {
        font-size: 16px;
        margin-right: 6px;
        font-weight: bold;
        margin-left: 4px;
      }
    }

    .r-links {
      .go-cart {
        display: block;
        background: #000;
        width: 100px;
        text-align: center;
        height: 28px;
        line-height: 26px;
        border-radius: 4px;
        color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        transition: all 0.2s;
        .iconfont {
          margin-right: 4px;
        }
        &:hover {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
          background: rgba(0, 0, 0, 0.815);
        }
      }
    }
  }
}
.nav {
  flex: 1;
  ul {
    display: flex;
    align-items: center;
  }
  li {
    font-size: 18px;
    padding: 0 20px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.3s;
    height: 26px;
    line-height: 38px;
    font-weight: bold;
    &.active {
      color: #000;
    }
    &:hover {
      color: #000;
      font-weight: bold;
    }
  }
}
// transform: translateY(0px);
@keyframes upDown {
  0% {
    transform: translateY(2px);
  }
  25% {
    transform: translateY(4px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0);
  }
}
// .language {
//   text-align: right;
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   margin: 10px auto;
//   .label {
//     font-size: 16px;
//     margin-right: 10px;
//     color: #fff;
//     font-weight: bold;
//   }
//   .text {
//     background: #3483fa;
//     color: #fff;
//     padding: 0 10px;
//     height: 32px;
//     line-height: 32px;
//     border-radius: 4px;
//     cursor: pointer;
//     text-align: center;
//   }
// }
.el-dropdown-link {
  cursor: pointer;
  color: #606266;
  margin-right: 15px;
}
</style>
<style lang="less">
.language {
  width: 62px;
  padding-right: 15px;
  box-sizing: content-box;
  border-right: 1px solid #eee;
  .el-input__inner {
    border: 0;
  }
}
</style>