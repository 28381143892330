import { CART_LIST, CART_ALL, UN_CART_ALL, CHECK_ITEM } from './mutations-types.js'
import { Message, MessageBox } from 'element-ui';
import request from '@/utils/request.js'


export default {
  state: {
    list: [],
    selectList: []//选中的数据
  },
  getters: {
    isCheckedAll(state) {
      return state.list.length == state.selectList.length;
    },
    total(state) {
      let total = {
        num: 0,
        price: 0
      }
      state.list.forEach(v => {
        if (v.checked) {
          total.num += parseInt(v.goods_num);
          total.price += v.goods_num * v.goods_price
        }
      })
      return total
    }
  },
  mutations: {
    // 存储购物车数据
    [CART_LIST](state, cartArr) {
      state.list = cartArr;
      // state.selectList = cartArr;
      // 初始化全选
      state.selectList = state.list.map(v => {
        return v.id
      })
    },
    // 全选
    [CART_ALL](state) {
      state.selectList = state.list.map(v => {
        v.checked = true;
        return v.id;
      });
    },
    // 全不选
    [UN_CART_ALL](state) {
      state.list.forEach(v => {
        v.checked = false
      });
      state.selectList = []
    },
    //单选
    [CHECK_ITEM](state, index) {
      console.log(index);
      let id = state.list[index].id
      let i = state.selectList.indexOf(id)
      // 点击切换checked实现双向绑定
      state.list[index].checked = !state.list[index].checked
      //如果能找到，就给它删除
      if (i > -1) {
        return state.selectList.splice(i, 1);
      }
      //如果之前没有选中，就给selectList添加一个id进去
      state.selectList.push(id);
    },
    //多个删除
    delGoods(state) {
      state.list = state.list.filter(v => {
        return state.selectList.indexOf(v.id) == -1
      })

    }
  },
  actions: {
    //控制全选和全不选
    checkedAllFn({ commit, getters }) {
      getters.isCheckedAll ? commit('UN_CART_ALL') : commit('CART_ALL')
    },
    delGoodsFn({ state, commit }, id) {
      console.log(id)
      // 如果没有选中，则提示信息
      if (state.selectList.length == 0) {
        Message('请选择删除的商品')
      }
      let arrCart = [];
      MessageBox({
        title: '提示',
        message: '确定删除商品吗?',
        showCancelButton: true
      }).then(res => {
        if (res == 'confirm') {
          if (typeof id == 'number') {
            //单个删除
            arrCart.unshift(id)
            //去cartInfo找和id相同的id删除掉实现页面上的更新
            let index = state.list.findIndex(v => {
              return v.id = id
            })
            state.list.splice(index, 1)
          } else {
            //多个删除
            arrCart = state.selectList;
            console.log('点击了多个old', arrCart, typeof arrCart)
            //要把是勾选状态的物品全部删除,实现视图层的
            commit('delGoods')
            // //全不选
            // commit('UN_CART_ALL');

          }
          request({
            url: "/api/deleteCart",
            method: "post",
            data: {
              arrId: arrCart
            }
          }).then(res => {
            if (res.success) Message(res.msg)
          });
        } else {
          console.log('取消了')
        }
      })
    }
  },
  module: {}
}