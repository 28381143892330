<template>
  <!-- 卡片轮播 -->
  <div class="cardRotation defaultStyle">
    <div class="Subhead1">
      <h2>Ofertas do dia</h2>
      <a>Ver todas</a>
    </div>
    <el-carousel :autoplay="false" indicator-position="none" height="500px" :loop="false">
      <el-carousel-item v-for="item in carouselList" :key="item.id">
        <el-row type="flex" :justify="computedJustify(item.list.length, 5)">
          <el-col
            :span="
            computedSpan(
              item.list.length,
              computedJustify(item.list.length, 5),
              5
            )
          "
            v-for="element in item.list"
            :key="element.id"
          >
            <a style="cursor: pointer;">
              <el-card shadow="hover" style="width: 224px" class="carouselCard">
                <img :src="element.src" />
                <div style="padding: 10px; text-align: left;">
                  <span style="text-decoration: line-through; font-size: 12px">
                    {{ element.noOp
                    }}
                  </span>
                  <div>
                    <span>{{ element.pp }}</span>
                    <span style="font-size: 6px; color: #00a650" v-show="element.offShow">
                      {{
                      element.off }}
                      <span>off</span>
                    </span>
                  </div>
                  <p style="color: #00a650" v-show="element.freeFreightShow">Frete grátis</p>
                  <div
                    style="
                        border-top: #bfbfbf solid 4px;
                        margin-top: 10px;
                        border-radius: 100px;
                      "
                  ></div>
                  <p style="font-size: 10px; margin-top: 5px">Esgotado</p>
                  <div style="font-size: 10px; margin-top: 10px">{{ element.commodityDetails }}</div>
                </div>
              </el-card>
            </a>
          </el-col>
        </el-row>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 卡片轮播
      carouselList: [
        {
          id: 1,
          // span: 4,
          list: [
            {
              id: 1,
              src: '/img/HomeViewIMG/10022.webp',
              off: '50%',
              offShow: true,
              noOp: 'R$ 49,99',
              pp: 'R$ 2.169',
              freeFreightShow: 'true',
              commodityDetails: 'Smart TV LG ThinQ AI 43UQ7500PSF 4K 43" 100V/240V',
            },
            {
              id: 2,
              src: '/img/HomeViewIMG/10022.webp',
              off: '50%',
              offShow: true,
              noOp: 'R$ 49,99',
              pp: 'R$ 2.169',
              freeFreightShow: 'true',
              commodityDetails: 'Smart TV LG ThinQ AI 43UQ7500PSF 4K 43" 100V/240V',
            },
            {
              id: 3,
              src: '/img/HomeViewIMG/10023.webp',
              off: '50%',
              offShow: true,
              noOp: 'R$ 49,99',
              pp: 'R$ 2.169',
              freeFreightShow: 'true',
              commodityDetails: 'Smart TV LG ThinQ AI 43UQ7500PSF 4K 43" 100V/240V',
            },
            {
              id: 4,
              src: '/img/HomeViewIMG/10024.webp',
              off: '50%',
              offShow: true,
              noOp: 'R$ 49,99',
              pp: 'R$ 2.169',
              freeFreightShow: 'true',
              commodityDetails: 'Smart TV LG ThinQ AI 43UQ7500PSF 4K 43" 100V/240V',
            },
            {
              id: 5,
              src: '/img/HomeViewIMG/10025.webp',
              off: '50%',
              offShow: true,
              noOp: 'R$ 49,99',
              pp: 'R$ 2.169',
              freeFreightShow: 'true',
              commodityDetails: 'Smart TV LG ThinQ AI 43UQ7500PSF 4K 43" 100V/240V',
            },
          ],
        },
        {
          id: 2,
          // span: 4,
          list: [
            {
              id: 1,
              src: '/img/HomeViewIMG/10022.webp',
              off: '50%',
              offShow: true,
              noOp: 'R$ 49,99',
              pp: 'R$ 2.169',
              freeFreightShow: 'true',
              commodityDetails: 'Smart TV LG ThinQ AI 43UQ7500PSF 4K 43" 100V/240V',
            },
            {
              id: 2,
              src: '/img/HomeViewIMG/10022.webp',
              off: '50%',
              offShow: true,
              noOp: 'R$ 49,99',
              pp: 'R$ 2.169',
              freeFreightShow: 'true',
              commodityDetails: 'Smart TV LG ThinQ AI 43UQ7500PSF 4K 43" 100V/240V',
            },
            {
              id: 3,
              src: '/img/HomeViewIMG/10023.webp',
              off: '50%',
              offShow: true,
              noOp: 'R$ 49,99',
              pp: 'R$ 2.169',
              freeFreightShow: 'true',
              commodityDetails: 'Smart TV LG ThinQ AI 43UQ7500PSF 4K 43" 100V/240V',
            },
            {
              id: 4,
              src: '/img/HomeViewIMG/10024.webp',
              off: '50%',
              offShow: true,
              noOp: 'R$ 49,99',
              pp: 'R$ 2.169',
              freeFreightShow: 'true',
              commodityDetails: 'Smart TV LG ThinQ AI 43UQ7500PSF 4K 43" 100V/240V',
            },
            {
              id: 5,
              src: '/img/HomeViewIMG/10025.webp',
              off: '50%',
              offShow: true,
              noOp: 'R$ 49,99',
              pp: 'R$ 2.169',
              freeFreightShow: 'true',
              commodityDetails: 'Smart TV LG ThinQ AI 43UQ7500PSF 4K 43" 100V/240V',
            },
          ],
        },
        {
          id: 3,
          // span: 4,
          list: [
            {
              id: 1,
              src: '/img/HomeViewIMG/10022.webp',
              off: '50%',
              offShow: true,
              noOp: 'R$ 49,99',
              pp: 'R$ 2.169',
              freeFreightShow: 'true',
              commodityDetails: 'Smart TV LG ThinQ AI 43UQ7500PSF 4K 43" 100V/240V',
            },
            {
              id: 2,
              src: '/img/HomeViewIMG/10022.webp',
              off: '50%',
              offShow: true,
              noOp: 'R$ 49,99',
              pp: 'R$ 2.169',
              freeFreightShow: 'true',
              commodityDetails: 'Smart TV LG ThinQ AI 43UQ7500PSF 4K 43" 100V/240V',
            },
            {
              id: 3,
              src: '/img/HomeViewIMG/10023.webp',
              off: '50%',
              offShow: true,
              noOp: 'R$ 49,99',
              pp: 'R$ 2.169',
              freeFreightShow: 'true',
              commodityDetails: 'Smart TV LG ThinQ AI 43UQ7500PSF 4K 43" 100V/240V',
            },
            {
              id: 4,
              src: '/img/HomeViewIMG/10024.webp',
              off: '50%',
              offShow: true,
              noOp: 'R$ 49,99',
              pp: 'R$ 2.169',
              freeFreightShow: 'true',
              commodityDetails: 'Smart TV LG ThinQ AI 43UQ7500PSF 4K 43" 100V/240V',
            },
            {
              id: 5,
              src: '/img/HomeViewIMG/10025.webp',
              off: '50%',
              offShow: true,
              noOp: 'R$ 49,99',
              pp: 'R$ 2.169',
              freeFreightShow: 'true',
              commodityDetails: 'Smart TV LG ThinQ AI 43UQ7500PSF 4K 43" 100V/240V',
            },
          ],
        },
      ],
    };
  },
  computed: {
    // getIconBoxList(){
    //   return this.iconBoxList;
    // }
  },
  methods: {
    computedJustify(listLength, maxLength) {
      return listLength == maxLength ? 'space-around' : 'left';
    },
    computedSpan(listLength, justify, maxLength) {
      const col = 24;
      if (justify.indexOf('space') > -1) return col % listLength == 0 ? col / listLength : col % listLength;
      return 24 / maxLength;
    },
    init() {
      this.initImgList();
      // this.initIconBoxList();
    },
  },
};
</script>

<style lang="less" scoped>
/* 卡片轮播 */
.cardRotation.defaultStyle {
  margin-top: 60px;
  height: 340px;
}

.cardRotation.defaultStyle .Subhead1 {
  display: flex;
}

.cardRotation.defaultStyle .Subhead1 > h2 {
  font-size: 26px;
  font-weight: 300;
  margin-bottom: 20px;
}

.cardRotation.defaultStyle .Subhead1 > a {
  margin: 10px 0 0 10px;
  height: 20px;
  color: #3483fa;
}

.cardRotation .el-carousel__arrow--right {
  right: 0px;
}

.cardRotation .el-carousel__arrow--left {
  left: 20px;
}

.el-card.carouselCard img {
  margin-left: 0;
  width: 222px;
  // height: 220px;
  border-bottom: #ccc solid 1px;
}
</style>