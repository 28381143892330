import request from '@/utils/request.js'
/**
 * 刷新token
 */
export function handleRefreshToken(token) {
  return request({
    url: `/passport/member/refresh/${token}`,
    method: 'GET',
    needToken: false
  })
}
