<template>
  <div>
    <!-- 顶部导航栏  -->
    <Header></Header>
    <!-- 大轮播图  -->
    <div class="bigRotation">
      <el-carousel>
        <el-carousel-item v-for="item in getImageList" :key="item.id">
          <a target="_blank">
            <img style="width: 100%; height: 340px" :src="item.idView" />
          </a>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 支付  -->
    <div class="payment defaultStyle">
      <div class="textBox1">
        <i></i>
        <div class="text1">Pagamento rápido e seguro</div>
      </div>
      <div class="textBox2">
        <a class="textImg1">
          <img src="/img/HomeViewIMG/10012.svg" alt />
        </a>
        <div class="text3">Até 10 parcelas sem juros</div>
      </div>
      <div class="textBox2">
        <a class="textImg1">
          <img src="/img/HomeViewIMG/10013.svg" alt />
        </a>
        <div class="text3">Parcelamento sem cartão</div>
      </div>
      <div class="textBox3">
        <a class="textImg1">
          <img src="/img/HomeViewIMG/10014.svg" alt />
        </a>
        <div class="text3">Via Pix</div>
      </div>
      <a class="textImg2">
        <img src="/img/HomeViewIMG/10015.svg" alt />
      </a>
    </div>
    <!-- 卡片轮播 -->
    <CardRotation></CardRotation>
    <!-- 订阅 -->
    <!-- <div class="subscribe defaultStyle">
      <div class="subscribeHeader">
        <div class="subscribeHeaderleftText">Assine o nível 6</div>
        <div class="subscribeHeaderrightText">
          <span class="price1">R$ 49,99</span>
          <span class="price2"
            >R$ 14 <sup style="font-size: 10px">99</sup><span style="font-size: 10px">/mês</span></span
          >
        </div>
      </div>
      <div class="relyOn">
        <div class="relyOnText">Conte com os melhores benefícios no 3Cliques Livre e do 3Cliques Pago</div>
        <div class="iconBox1">
          <div class="icon1" v-for="item in iconBoxList" :key="item.id">
            <img :src="item.src" alt="" />
            <div style="width: 177px; font-size: 12px; line-height: 16px; margin-left: 16px; margin-right: 16px">
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
      <div class="Assinebtn">
        <el-button type="primary">Assine</el-button>
      </div>
    </div>-->

    <!-- 图片链接 -->
    <!-- <div class="imgLink defaultStyle">
      <div class="imgLinkText">NO 3Cliques LIVRE VOCÊ APROVEITA MUITO 💛</div>
      <div class="imgLinkBox">
        <a><img src="/img/HomeViewIMG/10080.png.webp" alt="" /></a>
        <a><img src="/img/HomeViewIMG/10081.webp" alt="" /></a>
      </div>
    </div>-->
    <!-- 可能感兴趣推荐 -->
    <!-- <div class="interestBox defaultStyle">
      <h2 style="font-size: 26px; font-weight: 300; margin-bottom: 20px">Confira</h2>
      <div style="display: flex; align-items: center; justify-content: space-between">
        <a v-for="item in imgLinkList" :key="item.id">
          <div
            class="leftImg"
            style="
              height: 250px;
              width: 580px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
            "
          >
            <div style="margin-left: 20px">
              <p
                style="
                  font-size: 12px;
                  letter-spacing: 4px;
                  margin-bottom: 10px;
                  color: #4b4b4b;
                  font-weight: 600;
                  line-height: 1;
                  margin-bottom: 8px;
                  text-transform: uppercase;
                "
              >
                {{ item.t1 }}
              </p>
              <p
                style="
                  font-size: 28px;
                  font-weight: 600;
                  color: #4b4b4b;
                  font-stretch: normal;
                  font-style: normal;
                  letter-spacing: normal;
                  line-height: 1.1;
                  text-transform: uppercase;
                "
              >
                {{ item.t2 }}
              </p>
              <p
                style="
                  font-size: 28px;
                  font-weight: 600;
                  color: #4b4b4b;
                  font-stretch: normal;
                  font-style: normal;
                  letter-spacing: normal;
                  line-height: 1.1;
                  text-transform: uppercase;
                  margin-bottom: 20px;
                "
              >
                {{ item.t3 }}
              </p>
              <el-button type="primary" size="medium">主要按钮</el-button>
            </div>
            <img style="width: auto; height: 100%" :src="item.url" alt="" />
          </div>
        </a>
      </div>
    </div>-->
    <!-- 商铺推荐 -->
    <div class="shopecommendation defaultStyle">
      <div style="display: flex">
        <h2 style="font-size: 26px; font-weight: 300">As melhores lojas te esperam</h2>
        <a style="margin: 8px 0 0 14px" href>Ver Lojas</a>
      </div>
      <div class="sbox1">
        <div
          class="spb"
          style="height: 360px; width: 290px; background-color: #fff; cursor: pointer"
          v-for="element in shopArray"
          :key="element.id"
        >
          <div>
            <img :src="element.url1" />
          </div>
          <div>
            <img
              style="width: 80px; height: 80px; margin: 0 auto; margin-top: -40px"
              :src="element.url2"
              alt
            />
          </div>
          <div style="text-align: center; margin: 50px 0 16px 0">
            <span
              style="
                color: #333;
                font-size: 24px;
                font-weight: 600;
                height: 35px;
                margin: 16px 0;
                overflow: hidden;
                padding: 0 16px;
                text-overflow: ellipsis;
                white-space: nowrap;
              "
            >{{ element.text }}</span>
          </div>
          <div>
            <a style="display: flex; justify-content: space-around" href>
              <img
                v-for="item in shopImgList"
                :key="item.id"
                style="width: 70px; height: 70px; border: solid 1px #eee"
                :src="item.src"
                alt
              />
            </a>
          </div>
          <div class="click1" style="text-align: center; margin-top: 40px">
            <a>Ver loja</a>
          </div>
        </div>
      </div>
    </div>

    <!-- 分类栏 -->
    <div class>
      <div></div>
    </div>

    <!-- 更多 -->
    <div class="more">
      <div class="learnMore defaultStyle">
        <div v-for="item in moreList" :key="item.id">
          <div>
            <img style="width: 66px; height: 46px" :src="item.url" alt />
          </div>
          <div>
            <h2
              style="color: #4b4b4b; font-size: 20px; font-weight: 500; line-height: 1.4; margin: 16px 0 0"
            >{{ item.t1 }}</h2>
          </div>
          <div style="margin-top: 20px; text-align: center">
            <span style="color: #999; font-size: 15px; line-height: 1.2">{{ item.t2 }}</span>
          </div>
          <div style="margin-top: 20px">
            <a style="color: #3483fa; font-size: 14px; text-decoration: none" href>{{ item.t3 }}</a>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部通栏 -->
    <Footer></Footer>
  </div>
</template>


<script>
import CardRotation from '@/components/home/CardRotation';
import Header from '@/components/Headers';
import Footer from '@/components/Footer';
export default {
  components: {
    Header,
    CardRotation,
    Footer,
  },
  data() {
    return {
      input3: '',
      imgList: [],
      iconBoxList: [
        {
          id: 1,
          src: '/img/HomeViewIMG/10026.svg',
          text: 'Disney+ sem custo',
        },
        {
          id: 2,
          src: '/img/HomeViewIMG/10027.svg',
          text: 'Star+ sem custo',
        },
        {
          id: 3,
          src: '/img/HomeViewIMG/10028.png',
          text: 'Frete grátis e rápido a partir de R$ 79 e 45% OFF em fretes de menos de R$ 79',
        },
        {
          id: 4,
          src: '/img/HomeViewIMG/10029.png',
          text: 'Mais descontos no 3Cliques Pago',
        },
      ],
      navBarList: [
        {
          id: 1,
          text: 'Ofertas do dia',
        },
        {
          id: 2,
          text: 'Histórico',
        },
        {
          id: 3,
          text: 'Moda',
        },
        {
          id: 4,
          text: 'Vender',
        },
        {
          id: 5,
          text: 'Contato',
        },
      ],

      //  列表显示隐藏
      // isShow: false,
      // 推荐商品
      imgLinkList: [
        {
          id: 1,
          url: '/img/HomeViewIMG/10099.webp',
          t1: 'FESTIVAL DE AUTO',
          t2: 'SEU CARRO NOVO',
          t3: 'ESTÁ AQUI',
        },
        {
          id: 2,
          url: '/img/HomeViewIMG/10099.webp',
          t1: 'FESTIVAL DE AUTO',
          t2: 'SEU CARRO NOVO',
          t3: 'ESTÁ AQUI',
        },
      ],
      // 商家列表
      shopArray: [
        {
          id: 1,
          url1: '/img/HomeViewIMG/10111.jpg',
          url2: '/img/HomeViewIMG/10100.webp',
          text: 'Brastemp',
        },
        {
          id: 2,
          url1: '/img/HomeViewIMG/10111.jpg',
          url2: '/img/HomeViewIMG/10100.webp',
          text: 'Brastemp',
        },
        {
          id: 3,
          url1: '/img/HomeViewIMG/10111.jpg',
          url2: '/img/HomeViewIMG/10100.webp',
          text: 'Brastemp',
        },
        {
          id: 4,
          url1: '/img/HomeViewIMG/10111.jpg',
          url2: '/img/HomeViewIMG/10100.webp',
          text: 'Brastemp',
        },
      ],
      // 商家图片列表
      shopImgList: [
        {
          id: 1,
          src: '/img/HomeViewIMG/10112.webp',
        },
        {
          id: 2,
          src: '/img/HomeViewIMG/10113.webp',
        },
        {
          id: 3,
          src: '/img/HomeViewIMG/10114.webp',
        },
      ],
      // 更多
      moreList: [
        {
          id: 1,
          url: '/img/HomeViewIMG/10115.svg',
          t1: 'Escolha como pagar',
          t2: 'Com o 3Cliques Pago, você paga com cartão, boleto ou Pix. Você também pode pagar em até 12x no boleto  com o 3Cliques Crédito.',
          t3: 'Como pagar com 3Cliques Pago',
        },
        {
          id: 2,
          url: '/img/HomeViewIMG/10116.svg',
          t1: 'Frete grátis a partir de R$ 79',
          t2: 'Só por estar cadastrado no 3Cliques Livre, você tem frete grátis em milhares de produtos. É um benefício do 3Cliques Pontos.',
          t3: 'Saiba mais sobre este benefício',
        },
        {
          id: 3,
          url: '/img/HomeViewIMG/10117.svg',
          t1: 'Segurança, do início ao fim',
          t2: 'Você não gostou do que comprou? Devolva! No 3Cliques Livre não há nada que você não possa fazer, porque você está sempre protegido.',
          t3: 'Como te protegemos',
        },
      ],
    };
  },
  computed: {
    getImageList() {
      return this.imgList;
    },
    // getIconBoxList(){
    //   return this.iconBoxList;
    // }
  },
  created() {
    this.init();
  },
  methods: {
    computedJustify(listLength, maxLength) {
      return listLength == maxLength ? 'space-around' : 'left';
    },
    computedSpan(listLength, justify, maxLength) {
      const col = 24;
      if (justify.indexOf('space') > -1) return col % listLength == 0 ? col / listLength : col % listLength;
      return 24 / maxLength;
    },
    init() {
      this.initImgList();
      // this.initIconBoxList();
    },
    initImgList() {
      for (var i = 5; i < 12; i++)
        this.imgList.push({
          id: i,
          idView: '/img/HomeViewIMG/' + (10000 + i) + '.webp',
        });
    },
    // initIconBoxList(){
    //   var i = 26;
    //   while(i < 30){
    //     this.iconBoxList.push({id: i, contentidView: "./img/HomeViewIMG/" + (10000 + i) + ".svg"})
    //     i++;
    //   }
    // }
    // mouseenter() {
    //   this.isShow = true;
    // },
    // mouseleave() {
    //   this.isShow = false;
    // },
    goGoodsInfo() {
      this.$router.push({
        name: 'OrderInfo',
      });
    },
  },
};
</script>

<style lang="less" scoped>
/* 大轮播图 */
.bigRotation {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.el-carousel__item a img {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
}

/* 支付 */
.payment {
  width: 1184px;
  height: 90px;
  display: flex;
  background-color: #fff;
  border: solid #ccc 2px;
  border-radius: 5px;
  margin-top: 50px;
}

.textBox1 {
  width: 260px;
  height: 90px;
  position: relative;
}

.textBox1 > i {
  background-color: #3483fa;
  bottom: 4px;
  content: '';
  left: 4px;
  position: absolute;
  top: 4px;
  width: 4px;
}

.text1 {
  margin-left: 20px;
  margin-top: 20px;
}

.text2 {
  margin-left: 20px;
  font-size: 14px;
}

.textBox2 {
  width: 300px;
  height: 90px;
  display: flex;
  padding-top: 20px;
}

.textBox3 {
  width: 200px;
  height: 90px;
  display: flex;
  padding-top: 20px;
}

.textImg1 {
  width: 48px;
  height: 48px;
  margin: 0 20px;
}

.text3 a {
  display: block;
  font-size: 14px;
  color: #5585fa;
}

.textImg2 {
  margin-top: 20px;
}

/* 订阅 */
.subscribe.defaultStyle {
  height: 340px;
  margin-top: 210px;
  background-color: white;
  border: solid #ccc 1px;
  border-radius: 1;
}

.subscribeHeader {
  background: linear-gradient(90deg, #a90f90 55%, #0c1a51);
  color: white;
  height: 90px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  font-size: 26px;
  font-weight: 600;
  align-items: center;
}

.subscribeHeaderleftText {
  margin-left: 20px;
}

.subscribeHeaderrightText {
  border-width: 0 0 0 3px;
  border-style: solid;
  border-color: white;
  margin-right: 20px;
  padding: 10px;
}

.price1 {
  font-size: 14px;
  font-weight: 500;
  text-decoration: line-through;
  display: block;
  height: 10px;
}

.price2 {
  font-size: 24px;
  font-weight: 600;
}

/* 依靠 */
.relyOn {
  height: 180px;
  border-bottom: 1px solid #e8e8e8;
  padding-top: 30px;
}

.relyOnText {
  margin-left: 30px;
}

.iconBox1 > div > img {
  width: 80px;
  height: 80px;
}

.icon1 {
  display: flex;
  align-items: center;
}

.iconBox1 {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.Assinebtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;
}

/* 图片链接 */
.imgLink.defaultStyle {
  margin-top: 20px;
  height: 340px;
  display: flex;
  flex-direction: column;
}

.imgLinkText {
  margin-top: 20px;
  text-align: center;
}

.imgLinkBox {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.imgLinkBox > a > img {
  width: 584px;
  height: 165px;
  border: #ccc solid 1px;
  cursor: pointer;
  border-radius: 5px;
}

.imgLinkBox > a > img:hover {
  box-shadow: #ccc 0px 10px 10px;
  border-radius: 5px;
}

// 商铺推荐
.spb .click1 a {
  color: #000;
}

.spb .click1:hover a {
  color: #1e80ff;
}

/* 可能感兴趣 */
.interestBox.defaultStyle .leftImg {
  background-color: #fff;
}

.interestBox.defaultStyle {
  margin-bottom: 100px;
}

.interestBox.defaultStyle > div > a:hover {
  -webkit-box-shadow: 0 7px 16px 0 rgb(0 0 0 / 20%), 0 1px 3px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 7px 16px 0 rgb(0 0 0 / 20%), 0 1px 3px 0 rgb(0 0 0 / 10%);
  -webkit-transition: -webkit-box-shadow 0.1s ease-out;
  transition: -webkit-box-shadow 0.1s ease-out;
  transition: box-shadow 0.1s ease-out;
  transition: box-shadow 0.1s ease-out, -webkit-box-shadow 0.1s ease-out;
}

// 可能感兴趣栏
.shopecommendation.defaultStyle > .sbox1 > .spb:hover {
  -webkit-box-shadow: 0 7px 16px 0 rgb(0 0 0 / 20%), 0 1px 3px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 7px 16px 0 rgb(0 0 0 / 20%), 0 1px 3px 0 rgb(0 0 0 / 10%);
  -webkit-transition: -webkit-box-shadow 0.1s ease-out;
  transition: -webkit-box-shadow 0.1s ease-out;
  transition: box-shadow 0.1s ease-out;
  transition: box-shadow 0.1s ease-out, -webkit-box-shadow 0.1s ease-out;
}

.shopecommendation.defaultStyle > div > a {
  color: #3483fa;
}

.shopecommendation.defaultStyle > .sbox1 {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}

// 更多
.more {
  height: 300px;
  background-color: #fff;
  margin-top: 100px;
}

.learnMore.defaultStyle {
  height: 300px;
  display: flex;
  justify-content: space-around;
}

.learnMore.defaultStyle > div {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cardRotation.defaultStyle {
  height: auto;
}
</style>
