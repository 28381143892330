import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "./i18n/index";
import ElementUI from 'element-ui';

// 引入公共初始化css
import "./assets/css/reset.css";

import "./assets/css/base.css";
// 引入公共css
import "./assets/css/common.less";
// 引入iconfont字体图标库
import "./assets/css/iconfont.css";
// element组件样式
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI);


// 滚动加载更多
Vue.directive('loadMore', {
  bind(el, binding) {
    // 获取element，定义scroll
    let select_dom = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
    select_dom.addEventListener('scroll', function () {
      let height = this.scrollHeight - this.scrollTop <= this.clientHeight;
      if (height) {
        binding.value()
      }
    })
  }
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
