// 用户
export const USER_LOGIN = 'USER_LOGIN';
export const INIT_USER = 'INIT_USER';
export const INIT_I18N = 'INIT_I18N';
// 购物车
export const CART_LIST = 'CART_LIST';
export const CART_ALL = 'CART_ALL';
export const UN_CART_ALL = 'UN_CART_ALL';
export const CHECK_ITEM = 'CHECK_ITEM'

// 地址
export const ADDRESS_DATA = 'ADDRESS_DATA'

// 订单状态
export const ORDER_STATUS = 'ORDER_STATUS'
