import Vue from "vue";
import VueI18n from "vue-i18n";
const { crc32 } = require("crc");
// import zh from "@/i18n/json/zh.json";
// import en from "@/i18n/json/en.json";
import ElementUI from 'element-ui'

import ptBrLocale from 'element-ui/lib/locale/lang/pt-br'        //引入Element UI的葡文包
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'     //引入Element UI的中文包
import enLocale from 'element-ui/lib/locale/lang/en'     // 引入Element UI的英文包

Vue.use(VueI18n);

const localLang = localStorage.getItem("lang"); // 业务需要存放仓库, 如element国际化, 需要刷新页面重新加载, 在main.js初始化element国际化语言. 这里根据你的业务去做语言切换功能.

const messages = {
  pt: {
    ...require('@/i18n/json/pt.json'),
    ...ptBrLocale // 或者用 Object.assign({ message: 'hello' }, enLocale)
  },
  zh: {
    ...require('@/i18n/json/zh.json'),
    ...zhLocale // 或者用 Object.assign({ message: '你好' }, zhLocale)
  },
  en: {
    ...require('@/i18n/json/en.json'),
    ...enLocale // 或者用 Object.assign({ message: 'hello' }, zhLocale)
  }
}

//实例化语言类
const i18n = new VueI18n({
  locale: localLang || "pt", // 语言标识
  fallbackLocale: localLang || "pt", //默认中文语言
  messages,
});
ElementUI.i18n((key, value) => i18n.t(key, value))


// --------这里是i18next-scanner新增的配置-------------
function lang(key) {
  let hashKey = `K${crc32(key).toString(16)}`; // 将中文转换成crc32格式去匹配对应的json语言包
  let words = i18n.t(hashKey);
  if (words == hashKey) {
    words = key;
    console.log(key, "-无匹配语言key");
  }
  return words;
}
Vue.prototype.$lang = lang; // 注入全局, 页面$lang('xxx')调用
// --------这里是i18next-scanner新增的配置-------------

// export { i18n };

export default i18n