<template>
  <div id="app">
    <!-- <div class="notice" v-if="flag">
      Sistema está em manutenção, por favor, acessar por volta das 12:30 horário Brasília,
      dia 15/02/2023
      <span @click="close">close</span>
    </div> -->
    <router-view />
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
export default {
  data() {
    return {
      flag: true,
    };
  },
  created() {
    this.$store.commit('INIT_USER');
    const langList = {
      // loginOut: this.$t('storeUser.loginOut'), //确定退出登录吗？
      // changePwdTips: this.$t('storeUser.changePwdTips'), //密码修改成功，请重新登录！
      // btnConfirm: this.$t('common.btnConfirm'), //确认
      // btnCancel: this.$t('common.btnCancel'), //取消
      // titleTip: this.$t('common.titleTip'), //提示
      outTips: this.$lang('退出成功'), //退出成功
      tipsLogin: this.$lang('请登录后执行此操作！'), //请登录后执行此操作！
      tipsTitle: this.$lang('请登录'), //请登录
      nowLogin: this.$lang('立即登录'), //立即登录
      browse: this.$lang('继续浏览'), //继续浏览
      errCode1: this.$lang('连接超时，请稍候再试！'), //连接超时，请稍候再试！
      errCode2: this.$lang('网络错误，请稍后再试！'), //网络错误，请稍后再试！
    };
    this.INIT_I18N(langList);
  },
  // mounted() {
  //   console.log(process.env, 'process.env');
  //   console.log(process.env.VUE_APP_BASE_URL, 'VUE_APP_URL');
  // },
  methods: {
    ...mapMutations(['INIT_I18N']),
    close() {
      // 关闭
      this.flag = false;
    },
  },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center;
  color: #2c3e50; 
  min-height: 100vh; 头部固定时不app不能使用100vh */
}
.notice {
  z-index: 9999;
  position: absolute;
  top: 0;
  width: 100%;
  background: #f36767;
  text-align: center;
  padding: 10px;
  color: #fff;
}
.notice span {
  margin-left: 20px;
  background: #fff;
  color: #000;
  border-radius: 4px;
  padding: 2px 10px;
  cursor: pointer;
}
</style>
