// import Vue from 'vue'
// import router from '@/router'
// import store from '@/store'
import i18n from "../i18n/index";
const { crc32 } = require("crc")

/**
 * 获取uuid
 */
export function getUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
  })
}

/**
 * 循环冗余校验
 * @param {*} i18n
 * @param {*} key 
 * @returns 
 */
export function crcLang(key) {
  let hashKey = `K${crc32(key).toString(16)}`; // 将中文转换成crc32格式去匹配对应的json语言包
  let words = i18n.t(hashKey);
  if (words == hashKey) {
    words = key;
  }
  return words;
}