import { ORDER_STATUS } from './mutations-types.js'

export default {
  state: {
    orderStatus: {}
  },
  mutations: {
    [ORDER_STATUS](state, data) {
      state.orderStatus = data
    }
  }
}