import { ADDRESS_DATA } from './mutations-types.js'

export default {
  state: {
    allCity: {}
  },
  mutations: {
    [ADDRESS_DATA](state, info) {
      state.allCity = info
    }
  }
}