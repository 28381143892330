import { USER_LOGIN, INIT_USER, INIT_I18N } from './mutations-types.js'
import { Message } from "element-ui"
import { loginOutApi } from "@/utils/api/login";

import router from '@/router'

export default {
  state: {
    loginStatus: false,//登录状态
    token: null,	//token
    userInfo: {},//用户信息：用户的头像|用户昵称
    i18nList: JSON.parse(localStorage.getItem('i18nList')) || {},
    showTop: false
  },
  getters: {

  },
  mutations: {
    //设置
    [USER_LOGIN](state, user, token) {
      state.loginStatus = true
      if (token) {
        state.token = token.accessToken
        localStorage.setItem('accessToken', token.accessToken);
        localStorage.setItem('refreshToken', token.accessToken);
      }
      state.userInfo = user

      // 持久化存储==》本地存储
      localStorage.setItem('userInfo', JSON.stringify(user))
    },
    // 读取
    [INIT_USER](state) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let accessToken = localStorage.getItem('accessToken')
      if (userInfo) {
        state.loginStatus = true
        state.token = accessToken
        state.userInfo = userInfo
      } else {
        state.loginStatus = false
        state.token = null;
        state.userInfo = {};
      }
    },
    [INIT_I18N](state, value) {
      localStorage.setItem('i18nList', JSON.stringify(value));
      state.i18nList = value
    },
    // 退出登录
    async loginOut(state) {
      await loginOutApi().then(res => {
        if (!res.success) {
          throw res.msg
        }
        state.loginStatus = false;
        state.token = null;
        state.userInfo = {};
        localStorage.removeItem('userInfo')
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        router.push({
          path: '/login',
          query: {
            rePath: router.history.current.path,
            query: JSON.stringify(router.history.current.query)
          }
        });

        Message({
          type: 'success',
          message: state.i18nList.outTips
        });
      })
    },
    async loginOutNoteMsg(state) {
      await loginOutApi().then(res => {
        if (!res.success) {
          throw res.msg
        }
        state.loginStatus = false;
        state.token = null;
        state.userInfo = {};
        localStorage.removeItem('userInfo')
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        router.push('/login')
      })
    },
    addShow(state, bool) {
      state.showTop = bool;
    }
  },
  actions: {

  }

}